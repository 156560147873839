



























































import Vue from "vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";

export default Vue.extend({
  name: "SelectOperation",
  components: { GoBackHeader }
});
